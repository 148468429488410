import img1 from '@pages/Install/components/Comments/assets/1.png'
import img2 from '@pages/Install/components/Comments/assets/2.png'
import img3 from '@pages/Install/components/Comments/assets/3.png'
import img4 from '@pages/Install/components/Comments/assets/4.png'
import img5 from '@pages/Install/components/Comments/assets/5.png'
import img6 from '@pages/Install/components/Comments/assets/6.png'
import img7 from '@pages/Install/components/Comments/assets/7.png'
import img8 from '@pages/Install/components/Comments/assets/8.png'
import img9 from '@pages/Install/components/Comments/assets/9.png'
import img10 from '@pages/Install/components/Comments/assets/10.png'
import img11 from '@pages/Install/components/Comments/assets/11.png'
import img12 from '@pages/Install/components/Comments/assets/12.png'
import img13 from '@pages/Install/components/Comments/assets/13.png'
import img14 from '@pages/Install/components/Comments/assets/14.png'
import img15 from '@pages/Install/components/Comments/assets/15.png'
import img16 from '@pages/Install/components/Comments/assets/16.png'
import img17 from '@pages/Install/components/Comments/assets/17.png'
import img18 from '@pages/Install/components/Comments/assets/18.png'
import img19 from '@pages/Install/components/Comments/assets/19.png'
import img20 from '@pages/Install/components/Comments/assets/20.png'
import img21 from '@pages/Install/components/Comments/assets/21.png'
import img22 from '@pages/Install/components/Comments/assets/22.png'
import img23 from '@pages/Install/components/Comments/assets/23.png'

export const listComments = [
  {
    icon: img1,
    name: 'সৌরভ মুখার্জি',
    countStarts: 5,
    date: 'April 8, 2024',
    comment: '"Crazy Time" একটি অসাধারণ অ্যাপ্লিকেশন, যা আমি সবার কাছে সুপারি করছি।',
    peopleFound: 2,
  },
  {
    icon: img2,
    name: 'অনির্বাণ চক্রবর্তী',
    countStarts: 16,
    date: 'April 1, 2024',
    comment:
      'Crazy Time অসাধারণভাবে কাজ করে এবং দ্রুত অর্থ উত্তোলন করে। আমি সবাকে এই অ্যাপ্লিকেশনটি ডাউনলোড করার জন্য অনুপ্রেরণা দিচ্ছি।',
    peopleFound: 16,
  },
  {
    icon: img3,
    name: 'দেবাশিষ দত্ত',
    countStarts: 5,
    date: 'March 29, 2024',
    comment:
      'ডাউনলোড করুন করা হয়েছে অসাধারণ "Crazy Time" অনলাইন ক্যাসিনো অ্যাপ, ভালো জয়, উচ্চ কোয়ালিটির মুল্যায়ন এবং দ্রুত পেআউট। খুব ভালো!!!',
    peopleFound: 54,
  },
  {
    icon: img4,
    name: 'অভিজিৎ সেন',
    countStarts: 4,
    date: 'March 25, 2024',
    comment:
      'দারুণ "Crazy Time" অ্যাপ। আমরা এটি সুখ সাথে ব্যবহার করি। খুব সুবিধাজনক এবং বোঝার জন্য। খেলা করা স্পোর্টসে দান করা মজাদার.. আমি এই অ্যাপ্লিকেশনটি সুপারি করছি...',
    peopleFound: 1,
  },
  {
    icon: img5,
    name: 'কামল ঘোষ',
    countStarts: 5,
    date: 'March 20, 2024',
    comment:
      '"Crazy Time" অ্যাপ টপ লেভেল, আমার খুব ভালো লেগেছে, সত্যিই শখ পূর্ণ "Crazy Time", আত্মবিশ্বাস করুন, এটি খুব ভালো, আমি সবার কাছে "Crazy Time" পরিচিতি করাচ্ছি কারণ এটি সত্যিই ভালো, আমি সত্যিই "Crazy Time" পছন্দ করছি, সত্যিই "Crazy Time" ডাউনলোড করুন, এখানে আপনি অসুবিধা হবেন না, আপনি খুব দ্রুত আয় করবেন, আপনি অসুবিধা হবেন না, আপনি ইয়ার্ন করা অর্থ অপ্রয়োজন, সবাইকে "Crazy Time" ডাউনলোড করার জন্য আমি সুপারি করছি।',
    peopleFound: 22,
  },
  {
    icon: img6,
    name: 'রিত্বিক বসু',
    countStarts: 5,
    date: 'March 9, 2024',
    comment:
      'আস্চর্য্যকর খেলা, আমার খুব পছন্দ হয়েছে, অনেক সুবিধা, অনেক সাত্ত্বিক ভাবনা, অনেক অতিরিক্ত ফাংশন, যদি কিছু অসুবিধা থাকে যা সংশোধনের জন্য কাজ করতে হয়, তবে সামগ্রিকভাবে আমি অ্যাপ্লিকেশন এবং এটির সাথে Crazy Time খুব বিশেষ ভাবে সন্তুষ্ট, আমি সত্যিই সবার কাছে এটি পছন্দ হয়েছে এবং মনোভাব ভালো ছিল।',
    peopleFound: 6,
  },
  {
    icon: img7,
    name: 'অরিন্দম পাল',
    countStarts: 5,
    date: 'March 1, 2024',
    comment:
      'একটি ভাল অ্যাপ্লিকেশন, যেখানে অনেক ভাল খেলা রয়েছে যা আপনি খেলতে এবং জিততে পারেন, "Crazy Time" একটি অসাধারণ ক্যাসিনো, আমি নিজেই খেলেছি এবং অনেক জিতেছি এবং আমি আপনাকে আসতে এবং খেলতে উপদেশ দিচ্ছি, এটি চেষ্টা করুন, আমি আপনাকে বিশ্বাস করতে বলছি, আপনি স্বাভাবিকভাবে জিততে এবং দ্রুত উত্তোলন করতে পারেন।',
    peopleFound: 0,
  },
  {
    icon: img8,
    name: 'শৌরভ রায়',
    countStarts: 5,
    date: 'February 26, 2024',
    comment:
      'আমি অনেকগুলি ক্যাসিনো এবং বুকমেকারে খেলেছি। "Crazy Time" প্রতিস্থানে সেরা। তবে প্রধান মাপকাঠি হল এক মিনিটের মধ্যে অর্থ উত্তোলন। আমি এটি সুপারি করছি। অসাধারণ লাইন, দারুন সম্ভাবনাসমৃদ্ধতা, সুন্দর ডিজাইন, অসাধারণ স্লট, সুবিধাজনক অ্যাপ্লিকেশন মেনু। আমি বিশেষ ভাবে তথ্যসাধনের দিকে মনোনিবেশ করতে চাই! অফিস প্রাপ্তি বিনা কমিশনে, 5-20 মিনিটের মধ্যে উত্তোলন পর্যায়ক, ধন জমা করার জন্য অনেক সরঞ্জাম।',
    peopleFound: 34,
  },
  {
    icon: img9,
    name: 'সুব্রত রায়',
    countStarts: 5,
    date: 'February 24, 2024',
    comment:
      'শুভ সন্ধ্যা, আমি আপনাদেরকে "Crazy Time" অ্যাপ্লিকেশনে পরিচিত করার ইচ্ছা করছি, আমার মতে এটি সেরা অ্যাপ্লিকেশন, যা ভালোভাবে কাস্টমাইজ করা যায়, আকর্ষণীয়, চিত্রময়, আপনি এটি নিজেই চেষ্টা করাটি ভালো। আসুন এবং নিজেই দেখুন, আপনার পছন্দ হবে, আপনি অমুল্যেয় এবং সাত্ত্বিক ভাবনা পাবেন।',
    peopleFound: 9,
  },
  {
    icon: img10,
    name: 'প্রতীক মজুমদার',
    countStarts: 5,
    date: 'February 22, 2024',
    comment:
      '"Crazy Time" সম্ভাব্যতঃ আমার অন্যান্য ক্যাসিনোগুলির মধ্যে আমার প্রিয়। আমার জন্য ব্যক্তিগতভাবে, গুণগত গ্রাফিক্স এবং ব্যবহারকারী-বন্ধুত্বপূর্ণ ইন্টারফেস, যা সত্যিকার দ্বারা শান্তি বোধ করতে দিয়, তা গুরুত্বপূর্ণ। এটেমপ্ট করা এবং আপনার ভাগ্য চেষ্টা করার সত্যিকার সুযোগ দিয়ে এটি মিশেছে। নতুনদের জন্য প্রচারণাত্মক কোড এবং স্বাগত বোনাস রয়েছে। আমি এটি তাদের জন্য সুপারি করছি যারা এই ক্ষেত্রে নিজেকে চেষ্টা করতে চান। আমার রেটিং 5 স্টার।',
    peopleFound: 1,
  },
  {
    icon: img11,
    name: 'অভিষেক বট',
    countStarts: 4,
    date: 'February 18, 2024',
    comment: 'সুপার ক্যাসিনো অফিস Crazy Time। আমি এই অ্যাপটি সত্যিই পছন্দ করছি।',
    peopleFound: 22,
  },
  {
    icon: img12,
    name: 'রহিত ঠাকুর',
    countStarts: 4,
    date: 'February 1, 2024',
    comment:
      'সকলকে হ্যালো, আমি আপনাদেরকে একটি অসাধারণ ক্যাসিনো অ্যাপ্লিকেশন সম্পর্কে জানাতে চাই। ইন্টারফেস অত্যন্ত আকাশমণি, টাকা দ্রুত এবং সমস্যা ছাড়াই কার্ডে প্রত্যাহার, এটি খুব সৃজনশীল এবং চমৎকার একটি অ্যাপ্লিকেশন, আমি এটি থেকে খুব অনেক টাকা প্রত্যাহার করছি মাত্র কাউচে শোয়া, সবাইকে ডাউনলোড করতে এবং চেষ্টা করতে, আপনার ভাগ্য ধরুন। ভালো খেলা',
    peopleFound: 43,
  },
  {
    icon: img13,
    name: 'সুমিত সাহা',
    countStarts: 5,
    date: 'January 30, 2024',
    comment:
      'হ্যালো বন্ধুরা, আমি আপনাদের সাথে ভাগাভাগি করতে চাই আমার Crazy Time অ্যাপ্লিকেশন সম্পর্কে !!! যদিও আমি একটি গেম্বলিং ব্যক্তি নই, আমি এই অ্যাপ্লিকেশনের দ্বারা অবাক হয়েছি, আপনি নিরাপদে বেট করতে এবং জিততে পারেন, এবং টাকা প্রত্যাহার করা খুব সুবিধাজনক। আলোকময় এবং স্পষ্ট গ্রাফিক্স! সামগ্রিকভাবে, সমস্ত নতুন এবং উৎসাহী খেলোয়াড়দের জন্য শুভকামনা',
    peopleFound: 14,
  },
  {
    icon: img14,
    name: 'অমিতাভ বিস্বাস',
    countStarts: 4,
    date: 'January 28, 2024',
    comment:
      'Crazy Time একটি ভালো এবং সহজ উপার্জন কোম্পানি। আমি এটি ডাউনলোড করেছি এবং আমি এর সাথে আত্মরস খেলি এবং যে টাকা আমি উপার্জন করি, তা আমি একটি ব্যাংক একাউন্ট বা সাথেই ই-ওয়ালেটে প্রত্যাহার করি। আপনাকে খেলার চেষ্টা করার জন্য উপদেয় করছি, আপনি এটি অপরিহার্যই দেখতে হবে। আমি ইতিমধ্যে এটি সবাইকে আমার সব বন্ধু এবং পরিচিতদের উপর সুপারি করেছি',
    peopleFound: 67,
  },
  {
    icon: img15,
    name: 'সনীল রায়চৌধুরী',
    countStarts: 5,
    date: 'January 20, 2024',
    comment:
      'একটি খুব ভালো Crazy Time অ্যাপ। আমি এটি খুব ভালোভাবে পছন্দ করি। আমি সবারকথা এই আবিষ্কারকারী, আশ্চর্যজনক অ্যাপ্লিকেশনটি ডাউনলোড করতে সহজ এবং ভালো কাজ করছে। এই অ্যাপ্লিকেশনটি ডাউনলোড করার জন্য আমি অত্যন্ত সুপারি করি, উদ্ভাবকেরা এমন একটি অসাধারণ অ্যাপ্লিকেশনের জন্য ধন্যবাদ',
    peopleFound: 0,
  },
  {
    icon: img16,
    name: 'অনিরুদ্ধ কার',
    countStarts: 5,
    date: 'January 19, 2024',
    comment:
      'Crazy Time বেটিং এর জন্য শীর্ষ সেরা অ্যাপ্লিকেশন, এটি অবাকজনক এবং 20 থেকে 12,000 পর্যন্ত শুরু হয়। এটি সহজ এবং সহজ। আমি আগে এই ধরনের বাজি খেলতাম এবং সবসময় হারতাম, কিন্তু এভিয়েটরে সবকিছু সম্মানযোগ্য, আমি কখনই টাকা হারিয়েনি এবং যেকোনও প্রদান পদ্ধতিতে টাকা প্রত্যাহার করি। এতে ছাড়া, আপনি অন্যান্য এমন কিছু ওয়ালেটে টাকা স্থানান্তর করতে পারেন। আমি যদি আপনির সাথে তাত্ত্বিক হতে চাই না, তবে আপনি এটি নিজেই পরীক্ষা করতে বলতে চাই',
    peopleFound: 12,
  },
  {
    icon: img17,
    name: 'শ্রীজিত রমান',
    countStarts: 5,
    date: 'January 28, 2024',
    comment:
      'Crazy Time, সমস্তের জন্য একটি শান্ত অ্যাপ্লিকেশন এবং এটি কোনও সমস্যা এবং অন্য কিছু নেই। সহজ, বুঝতে সহজ অ্যাপ্লিকেশন। এটি একটি নবজমীন ও তার মাত্র কৌশলে তা বোঝা যায়, তাই আপনি ডাউনলোড, ইনস্টল করতে এবং খেলতে পারেন। এই দিকে, আপনি শুধু মন্তব্য দেওয়ার চেয়ে অতিরিক্ত অর্থ উপার্জন করতে পারেন খেলা দ্বারা। ডাউনলোড এবং ইনস্টল করুন।',
    peopleFound: 23,
  },
  {
    icon: img18,
    name: 'মৌসুম চৌধুরী',
    countStarts: 5,
    date: 'January 12, 2024',
    comment:
      'Crazy Time একটি অসাধারণ অ্যাপ্লিকেশন, স্পষ্ট ইন্টারফেস, অসাধারণ গ্রাফিক্স, কোনও ল্যাগ নেই। একটি নতুন এবং কোনও জটিলতা নেই। তাত্ত্বিকভাবে, এখানে আপনি অতিরিক্ত টাকা উপার্জন করতে পারেন! এটি একটি অসুস্থ উদাহরণ হলো! আমি এই অ্যাপ্লিকেশনটি দীর্ঘ সময় ধরে ব্যবহার করছি, এটি আমাকে কখনও হারিয়ে নি, কার্ডে প্রত্যাহার খুব সুবিধাজনক! সামগ্রিকভাবে, আমি এটি সবার কাছে সুপারি করছি।',
    peopleFound: 19,
  },
  {
    icon: img19,
    name: 'অজিত দাস',
    countStarts: 5,
    date: 'January 11, 2024',
    comment:
      'একটি অসাধারণ প্রতিষ্ঠান Crazy Time, বড় জয়, দ্রুত প্রদান এবং উচ্চ সম্ভাবনা, অনেক খেলা, খুব সুবিধাজনক এবং ব্যবহার করা সহজ, একটি নবজমীন কিছু ক্লিকে বোঝা যায় এবং আপনি ইতিমধ্যে টাকা উপার্জন করতে চলেছেন। এখানে আমি এই অ্যাপ্লিকেশন সম্পর্কে শুনেছি শীর্ষ মত্ত্ব এর ব্যত্নান্তরে! আমি এটি অনুমোদন করছি। ভালো লাইন, বড় সম্ভাবনা, সুন্দর ডিজাইন, আশ্চর্যজনক স্লট, সুবিধাজনক অ্যাপ্লিকেশন মেনুর জন্য আপনাদের সাথে একটি বিশেষ মন্তব্য জানাতে চাই! উইদ্রল পরিমাণের মধ্যে কমিশন ছাড়া প্রদান, জমা করার জন্য অনেক উপায়',
    peopleFound: 43,
  },
  {
    icon: img20,
    name: 'মনিষ মুখোপাধ্যায়',
    countStarts: 5,
    date: 'January 3, 2024',
    comment:
      'খুব ভালো একটি অ্যাপ্লিকেশন। আমি কখনও মনে করিনি যে আমি এত টাকা ইনকাম করতে পারতাম। সবাইকে এই অ্যাপ্লিকেশনটি ডাউনলোড করার জন্য আমি সুপারিশ করছি।',
    peopleFound: 26,
  },
  {
    icon: img21,
    name: 'বিক্রাম পাণ্ডে',
    countStarts: 5,
    date: 'January 3, 2024',
    comment:
      'Crazy Time অ্যাপ সত্যিই ভালো। আমি এটা এত সহজ হবে মনে করিনি। আপনি এটা ইনস্টল করলেই সেটা তাৎক্ষণিকভাবে কাজ করবে। সম্ভাব্যতঃ এটা আরও ভালো। তাই আমি মনে করলাম এটা আরেকটি প্রতারণা নয়। তবে আমি নিশ্চিত ছিলাম যে সেটা হয়নি।',
    peopleFound: 23,
  },
  {
    icon: img22,
    name: 'কৌশিক চৌধুরী',
    countStarts: 5,
    date: 'January 3, 2024',
    comment:
      'অ্যাপটি সুপার!!! শুরুতে আমি মনে করিনি যে আমি ক্যাসিনোতে এত বেশি জিততে পারতাম, কিন্তু এখন আমি প্রতিদিন 1000 টাকা ইনকাম করছি, আর সময়ে সময়ে 5 হাজার টাকা !!! আমি সবাইকে Crazy Time এপ্লিকেশনটি সুপারিশ করছি, যাতে আপনি টাকা ইনকাম করতে চান, আমার মতামত অনুযায়ী, সবাই বিবেচনা করবেন আপনি TopX ডাউনলোড করতে চান কি না, কিন্তু ব্যক্তিগতভাবে আমি এটি সুপারিশ করছি!!!!',
    peopleFound: 19,
  },
  {
    icon: img23,
    name: 'অর্ণভ বন্দ্যোপাধ্যায়',
    countStarts: 5,
    date: 'December 27, 2023',
    comment:
      'Crazy Time - এটি একটি ভাল অ্যাপ্লিকেশন, যেখানে আপনি স্টেক এবং ক্যাসিনোতে ভাল অর্থ উপার্জন করতে পারেন।',
    peopleFound: 67,
  },
]
