import {
  Container,
  ContentAbout,
  ContentUpdate,
  DateUpdate,
  DescriptionAbout,
  ItemImage,
  ListImage,
  TextData,
  TitleAbout,
  TitleUpdate,
  WrapperData,
  WrapperTitle,
} from './ContentInfo.styled'
import img1 from './assets/1.webp'
import img2 from './assets/2.webp'
import img3 from './assets/3.webp'
import img4 from './assets/4.webp'
import { ArrowForward, ArrowDown } from '@shared/assets'
import { useState } from 'react'

export const ContentInfo: React.FC = () => {
  const [isDescription, setIsDescription] = useState(false)

  return (
    <Container>
      <ListImage>
        <ItemImage src={img1} alt={'img-1'} />
        <ItemImage src={img2} alt={'img-2'} />
        <ItemImage src={img3} alt={'img-3'} />
        <ItemImage src={img4} alt={'img-3'} />
      </ListImage>
      <ContentAbout>
        <WrapperTitle>
          <TitleAbout>About this game</TitleAbout>
          {isDescription ? (
            <ArrowDown onClick={() => setIsDescription(prev => !prev)} />
          ) : (
            <ArrowForward onClick={() => setIsDescription(prev => !prev)} />
          )}
        </WrapperTitle>
        <DescriptionAbout $isDescription={isDescription}>
          🚀Crazy Time:TopX - বিশেষ করে বাংলাদেশের জন্য! 🇧🇩🇧🇩🇧🇩
          <br />
          <br />
          😱 আপনার সুযোগ মিস করবেন না- ৳ 5,000,000
          <br />
          🤑 আমরা যোগাযোগ করছি! অ্যাপটি ডাউনলোড করুন এবং একটি 100% বোনাস এবং 175 এফএস পান! 💸
        </DescriptionAbout>
        <ContentUpdate>
          <TitleUpdate>Updated on</TitleUpdate>
          <DateUpdate>May 25, 2024</DateUpdate>
        </ContentUpdate>
        <ContentUpdate>
          <WrapperTitle>
            <TitleAbout>Data safety</TitleAbout>
            <ArrowForward />
          </WrapperTitle>
          <DescriptionAbout>
            In this section, developers can specify how applications collect and use data.
          </DescriptionAbout>
          <WrapperData>
            <TextData>No information.</TextData>
          </WrapperData>
        </ContentUpdate>
      </ContentAbout>
    </Container>
  )
}
